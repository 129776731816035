import 'babel-polyfill';
import { getToken } from '../_helpers/commonFunctions';
import call from './apiRequest';
import apiEndpoints from './apiEndpoints';

/**
 * Api call to set employee location
 * @param {object} location
 */
const setEmployeeLocation = (location, isUpdate = false) => {
  if (isUpdate) {
    return call({
      method: 'put',
      endpoint: apiEndpoints.gpsEmployeeRefreshLocation,
      payload: location,
      authKey: getToken(),
    });
  } else {
    return call({
      method: 'post',
      endpoint: apiEndpoints.gpsEmployeeList,
      payload: location,
      authKey: getToken(),
    });
  }
}

export default setEmployeeLocation;
