import actionConstants from '../constants/ActionConstants';
import { setEmployeeLocation } from '../../api';
import ActionDispatcher from '../../api/ActionDispatcher';

const setEmployeeLocationAction = (location, isUpdate = false) =>
  ActionDispatcher(
    setEmployeeLocation.bind(null, location, isUpdate),
    actionConstants.SET_EMPLOYEE_LOCATION_SUCCESS,
    actionConstants.SET_EMPLOYEE_LOCATION_FAILURE,
    actionConstants.SET_EMPLOYEE_LOCATION_REQUEST
  );

export default setEmployeeLocationAction;
