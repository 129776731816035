import React, { useEffect, useRef, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Selectors from '../../redux/selectors';
import setEmployeeLocation from '../../redux/actions/setEmployeeLocation';
import { getAddessWithCoordinates } from '../../utils/utils';
import _ from 'lodash';
import { getDistance } from 'geolib';

export const GeoLocationContext = React.createContext();

const ACCURATE_RADIO_METERS = 5; // 5 meters
const THROTLE_TIME = 10000; // 10 second

/**
 * @param {Number} secs - The seconds to convert to milliseconds
 * @returns The seconds converted to milliseconds
 */
function inMilliseconds(secs) {
  return secs * 1000;
}

export const GeoLocationProvider = ({ children }) => {
  const user = useSelector(Selectors.selectUser);
  const previousPosition = useRef(null)
  const lastUpdate = useRef(null)

  const geolocationValues = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
      timeout: inMilliseconds(75),
      maximumAge: inMilliseconds(75),
    },
    watchPosition: true,
    isOptimisticGeolocationEnabled: true,
    watchLocationPermissionChange: true,
  });

  const [gpsCaptureStatus, setGpsCaptureStatus] = useState('Reading GPS...');
  const dispatch = useDispatch();

  const [storedAddesses, setStoredAddesses] = useState( () => localStorage.getItem('storedAddesses') ? JSON.parse(localStorage.getItem('storedAddesses')) : []);

  /**
   * @param {*} dispatch The dispatch hook to call the save employee location action
   * @param {*} user The user object from redux
   * @param {*} coords The coordinates to save
   * @param {*} isGeolocationEnabled The GPS status
   * @returns The formatted address got from the API based on the coordinates
   */
  const saveLocation = async(dispatch, user, coords, isGpsEnabled, isUpdate = false) => {
    let formattedAddress = user.formattedAddress || ''   
    if( !_.isEqual(coords, getZeroCoordinates()) ) {
      const storedAddress = searchAddress(coords);
      if( storedAddress ) formattedAddress = storedAddress;
      else formattedAddress = await getAddessWithCoordinates(coords.latitude, coords.longitude)
      isGpsEnabled = true
    } else {
      isGpsEnabled = false
    }

    const locationData = {
      employeeId: user.customerId,
      customerId: user.ownerId ? user.ownerId : user.customerId,
      address: {
        type: 'Point',
        coordinates: [coords.latitude, coords.longitude],
      },
      formattedAddress,
      gpsEnabled: isGpsEnabled,
    };

    if( lastUpdate.current && (new Date() - lastUpdate.current) < THROTLE_TIME ) {
      console.log('Throttling the location update')
      return locationData.formattedAddress
    }
    lastUpdate.current = new Date()
    console.log('Updating the location')
    dispatch(setEmployeeLocation(locationData, isUpdate))
    return locationData.formattedAddress;
  };

  /**
   * @param {Object} coordinates The coordinates to search for based on lat and lng
   * @returns the address if it exists in the stored addresses, otherwise returns null
   */
  const searchAddress = (coordinates) => {
    const { latitude: lat, longitude: lng } = coordinates;
    const address = storedAddesses.find((item) => item.lat === lat.toFixed(4) && item.lng === lng.toFixed(4) );
    return address ? address.address : null;
  }

  /**
   * @param {Object} address If the address is not null and not exists in the stored addresses, then it will be added
   */
  const saveAddress = (address) => {
    if (address) {
      const { latitude: lat, longitude: lng } = geolocationValues.coords;
      const addressExists = searchAddress(geolocationValues.coords);
      if (!addressExists)
        setStoredAddesses([...storedAddesses, {
          lat: lat.toFixed(4),
          lng: lng.toFixed(4),
          address,
          coords: {
            latitude: lat,
            longitude: lng
          }  
        }]);
    }
  }

  const getZeroCoordinates = () => ({
    latitude: 0,
    longitude: 0
  })

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Re-enable geolocation or restart the geolocation watch
        geolocationValues.getPosition()
      }
    };

    const handleFocus = () => {
      // Re-enable geolocation or restart the geolocation watch
      geolocationValues.getPosition()
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('focus', handleFocus)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
    };
  }, [geolocationValues]);

  useEffect(() => {
    if (geolocationValues.coords) {
      const { latitude, longitude } = geolocationValues.coords;
      let isRefresh = false
      if (previousPosition.current) {
        const distance = getDistance(
          { latitude: previousPosition.current.latitude, longitude: previousPosition.current.longitude },
          { latitude, longitude }
        )  

        if (distance > ACCURATE_RADIO_METERS) {
          // Movement, store new position
          previousPosition.current = { latitude, longitude };
        } else {
          // Non-movement, just refresh the location
          isRefresh = true
        }
      } else {
        // First time, store the position
        previousPosition.current = { latitude, longitude };
      }

      saveLocation(dispatch, user, geolocationValues.coords, true, isRefresh)
        .then((address) => {
          if (address) saveAddress(address);
        })
    }
  }, [geolocationValues.coords]);

  // Position code errors https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
  // 1: PERMISSION_DENIED
  // 2: POSITION_UNAVAILABLE
  // 3: TIMEOUT
  useEffect(() => {
    if (geolocationValues.positionError && geolocationValues.positionError.code !== 1) {
      if (geolocationValues.positionError.code === 3) {
        setGpsCaptureStatus(`Took too long to fetch your location, we'll keep trying`);
      } else {
        setGpsCaptureStatus(`${geolocationValues.positionError.code} : ${geolocationValues.positionError.message}`);
      }
    } else {
      setGpsCaptureStatus('GPS is disabled')
    }
  }, [geolocationValues.positionError]);

  /**
   * Update the stored addresses in localStorage
   */
  useEffect(() => {
    localStorage.setItem('storedAddesses', JSON.stringify(storedAddesses));
  }, [storedAddesses]);

  return (
    <GeoLocationContext.Provider
      value={{
        ...geolocationValues,
        gpsCaptureStatus,
      }}
    >
      {children}
    </GeoLocationContext.Provider>
  );
};
