import logout from '../_helpers/LogoutHandler';
import { store } from '../redux/store/initStore';
import * as actionTypes from '../redux/constants/ActionConstants';
/**
 * Codes that will trigger a logout
 * 401 - Unauthorized, everything about the token 
 * 402 - Session not found, user does not exist, is deleted or is disabled
 */
const logoutDueToCodes = [401, 402] // codes that will trigger a logout

/**
 * Central action dispatcher
 * @param {function} serviceMethod
 * @param {string} actionTypeSuccess
 * @param {string} actionTypeFailure
 * @param {string} actionTypeInProgress
 * @param {object} extra
 * @param {function} callback
 */
const dispatchResponseToReducers =
  (
    serviceMethod,
    actionTypeSuccess,
    actionTypeFailure,
    actionTypeInProgress,
    extra,
    callback
  ) =>
  (dispatch) => {
    // dispatch action for in progress
    dispatch({
      type: actionTypeInProgress,
      extra,
    });
    const { shouldFetchData } = store.getState().offlineMode;
    if (shouldFetchData) {
      // dispatch loader action if the service method is not setEmployeeLocation because should be triggered at background
      /*if(!serviceMethod?.name?.includes("setEmployeeLocation") ) {
        dispatch({
          type: actionTypes.default.SHOW_LOADER_GLOBAL,
        })
      }*/

      // run service method if not in offline mode else dispatch failure action
      serviceMethod()
        .then((response) => {
          const { headers, body } = response;
          if (body.error) {
            // dispatch failure action with error code if the service method fails
            dispatch({
              type: actionTypeFailure,
              code: body.error && body.error.code,
              message: body.message,
              extra,
              headers,
            });
            if (callback) callback(headers, body);
          } else if (body.data) {
            // dispatch success action with payload if service method succeeds
            dispatch({
              type: actionTypeSuccess,
              payload: body.data,
              message: body.message,
              extra,
              headers,
              isRedirectedFromAdmin: false,
            });
            if (callback) callback(body.data);
          }
        })
        .catch((error) => {
          // handles error cases for service method
          if (error.status) {
            if ( logoutDueToCodes.includes(error.status) ) {
              const { auth } = store.getState();
              const user = auth.login && auth.login.user;
              if (user && user.token) {
                logout();
              }
            } else {
              dispatch({
                type: actionTypeFailure,
                code: error.status,
                message: error.response.body.message || error.message,
                extra,
              });
            }
          } else {
            dispatch({
              type: actionTypeFailure,
              code: 'NETWORK_ERROR',
              message:
                "We're sorry, we've experienced an error. Please quit the operation and try again. If the error persists please contact us for assistance.",
              extra,
            });
          }
          if (callback) callback(error.status, error.message);
        })
        .finally(() => {
          dispatch({
            type: actionTypes.default.HIDE_LOADER_GLOBAL,
          });
        });
    } else {
      dispatch({
        type: actionTypeFailure,
        code: 402,
        message: '',
        extra,
        payload: {
          message: '',
        },
      });
    }
    return '';
  };

export default dispatchResponseToReducers;
