export default {
  revenue: 'jobs/dashboard/revenuechart',
  jobsbChart: 'jobs/dashboard/jobchart',
  jobList: 'jobs/list',
  printJobList: 'jobs/list/print',
  qrCode: 'jobreport/qrcode',
  realTime: 'defaultReport/realTime',
  qrDetails: 'defaultReport/qrCode',
  clientList: 'clients/list',
  clientOrOwnerDetails: 'clients',
  setting: 'setting',
  customer: 'customers',
  employee: 'teams',
  transaction: 'user/inventory/transaction',
  employeeTimeCard: 'timeCard',
  updateStatus: 'timeCard',
  logout: 'admin/auth/logout',
  quickbook: 'quickbook/authurl',
  quickbookDelete: 'quickbook',
  quickbookDetail: 'quickbook/details',
  serviceRequests: 'service-request/list',
  bugTicketsFetch: 'bugs/list?',
  bugTicketsAddNew: 'bugs/',
  fileUpload: 'fileUpload/',
  login: 'login',
  jobReport: 'jobreport',
  jobs: 'jobs',
  updateHours: 'day',
  customerPortalFetchEmailList: 'clients/access',
  autoEmail: 'clients/update/autoemail',
  clientDetails: 'clients/',
  systemInfo: 'clients/buildinginfo/',
  addSystemOrDevice: 'clients/buildinginfo/systeminfo/',
  buildinginfo: 'clients/buildinginfo',
  addImageDetails: 'image',
  subscriptionPlans: 'payment/plans',
  subscription: 'payment/subscription',
  userCardCreate: 'payment/card',
  createPlan: 'payment/subscription',
  section: 'admin/section',
  clientDevice: 'clients/devices',
  clientDeleteDevice: 'device',
  clientDeviceList: 'list',
  clientUploadFile: 'file',
  quickBooksSync: 'quickbook/synch',
  companyLogo: 'customers/companyLogo/',
  updateClient: 'clients/update/',
  deficiency: 'deficiencies',
  forgetPassword: 'admin/auth/forgetPassword',
  resetPassword: 'admin/auth/resetPassword',
  customerDetail: 'customers/',
  genericReportType: 'generic/reportcategory/',
  importCustomer: 'clients/file',
  staticFolder: 'static',
  downloadCustomerSheet: 'static/clients.xlsx',
  customerSheetFile: 'clients.xlsx',
  downloadPartSheet: 'static/upload-parts.xlsx',
  getJobList: 'jobs/list',
  reportCategory: 'generic/reportcategory',
  reportCreate: 'jobreport',
  reportsFileUpload: 'fileUpload/',
  reportUpdate: 'jobreport/',
  partListDropDown: 'user/inventory/part/partsList/search',
  gpsEmployeeList: 'employee/location',
  gpsEmployeeDetail: 'employee/location/time',
  gpsEmployeeLocations: 'employee/location/today',
  gpsEmployeeRefreshLocation: 'employee/location/refresh',
  genericPermissions: 'generic/accessPermissions',
  chatList: 'chat/list',
  chatMessages: 'chat/message/list',
  allPeople: 'customers/teamList/chat',
  createIndividualChat: 'chat/channel',
  createGroupChat: 'chat/group',
  groupDetails: 'chat/group/details',
  updateGroupDetails: 'chat/group',
  getJobDetails: 'jobs',
  notificationCount: 'notification/count',
  teamListWithFilters: 'joblink',
  changePassword: 'admin',
  supplier: 'user/inventory/supplier',
  vehicle: 'user/inventory/vehicle',
  uploadParts: 'user/inventory/supplier/addParts',
  parts: 'user/inventory/part/getParts',
  addParts: 'user/inventory/part',
  reportList: 'jobreport',
  inventoryTools: 'user/inventory/tool/',
  inventoryToolAssign: 'user/inventory/tool/vehicle/assign',
  qrCodesPdf: 'customers/getQrCodes',
  unassignVehicle: 'user/inventory/vehicle/unassign',
  recurringInspections: 'jobs/rinspection',
  recurringInspectionsCount:  'jobs/rinspection/countjobs',
  recurringInspectionJobs:  'jobs/rinspection/getJobs',
  pricingLevel: 'user/inventory/price',
  assignParts: 'user/inventory/part/vehicle/assign',
  clientListForTypeChange: 'clients/update/list',
  jobReportList: 'jobreport',
  xlsxCount: 'generic/xlsxvalidation',
  fetchReportList: 'report/list',
  fetchRecurringInspectionDetail: 'jobs/rinspection',
  autoRouting: 'joblink/autorouting',
  deleteCustomerPortalEmail: 'clients/access',
  deleteAutoEmail: 'clients/update/autoemail',
  deleteRecurrInspection: 'jobs/rinspection',
  reportTemplateList: 'report/list',
  reportCreateInspection: 'defaultReport',
  reportInspectionAnswer: 'jobreport/',
  customerEmployeeListOfflineDownload: 'customers/customerDetails',
  subscriptionsUpdate: 'payment/subscription',
  permissionsUpdate: 'customers/permissions',
  privacyPolicy: 'customers/policy',
  getNotification: 'notification',
  readNotification: 'notification',
  registerDeviceId: 'customers/fcm',
  notification: 'notification',
  reportListByJobIds: 'jobreport/list',
  downloadReportPdf: 'report/downloadReport',
  clientDetailsSignature: 'clients/signature',
  serviceDueList: 'servicedue/list',
  serviceDueFor: 'servicedue/dueFor',
  autoGenerateInvoice: 'generic/invoiceno',
  questionJsonDefaultReports: 'defaultReport/allReports',
  deleteReportPdf: 'report/deleteReport',
  emailReportPdf: 'jobreport/mailPdf',
  completeJobManually: 'jobreport/manuall',
  legacyDevices: 'legacyDevices',
};
